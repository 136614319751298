import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { gsap, Power4 } from "gsap"
import Layout from "../components/layout"
import Seo from "../components/seo"
const About = ({ location }) => {
  const line1 = useRef(null)
  const line2 = useRef(null)
  const line3 = useRef(null)

  useEffect(() => {
    const tl = gsap.timeline()
    const paragraph = [
      line1.current.children[0],
      line2.current.children[0],
      line3.current.children[0],
    ]
    console.log(paragraph)

    tl.from(paragraph, {
      duration: 0.8,
      yPercent: 200,
      skewY: 10,
      ease: Power4.easeOut,
      stagger: 0.1,
    })
  }, [])
  return (
    <Layout location={location} title="About">
      <Seo title="About" />
      <div className="text-black pt-12 pb-16">
        <p className="font-medium tracking-wide  text-2xl sm:text-4xl md:text-5xl  ">
          <div className="flex flex-col justify-center  w-full">
            <div ref={line1} className="overflow-hidden relative">
              <div className="inline-block leading-tight">
                Hello, I am <span className="text-highlight">Marco</span>
              </div>
            </div>

            <div ref={line2} className="overflow-hidden relative">
              <div className="inline-block leading-tight">
                Currently working as a freelance web developer
              </div>
            </div>

            <div ref={line3} className="overflow-hidden relative">
              <div className="inline-block leading-tight">
                I am open for any opportunities, you can contact me through
                email or Linkedin.
              </div>
            </div>
          </div>
        </p>
      </div>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
